body { 
  --light: hsl(220, 50%, 90%);
  --primary: hsl(255, 30%, 55%);
  --focus: hsl(210, 90%, 50%);
  --border-color: hsla(0, 0%, 100%, 0.2);
  --global-background: hsl(220, 25%, 10%);
  --background: linear-gradient(
    to right,
    hsl(210, 30%, 20%),
    hsl(255, 30%, 25%)
  );
  --shadow-1: hsla(236, 50%, 50%, 0.3);
  --shadow-2: hsla(236, 50%, 50%, 0.4);
}

.page-header {
    background: var(--color-primary);
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  
  .page-header.inner {
    height: 5.5rem;
    margin-bottom: 4rem;
  }

  
  
  .page-header.inner .container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .page-header.inner h1 {
    margin: 0 auto;
  
    font-family: Inter;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.625rem;
  
    color: var(--color-header-inner-title);
  }


  @keyframes up {
    from {
      opacity: 0;
      transform: translateY(35px)
    }
    to {
      opacity: 1;
      transform: translateY(0)
    } 
  }
  
  .animate-up {
    transform: translateY(35px);
    opacity: 0;
  
    animation: up 200ms forwards;
  }
  
  @keyframes pop {
    from {
      opacity: 0;
      transform: scale(0);
  
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  
  .animate-pop {
    transform: scale(0);
    opacity: 0;
  
    animation: pop 300ms forwards;
  }
  
  .reverse {
    animation-direction: reverse;
  }
  
  .back {
    animation-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  
  .delay-1 {
    animation-delay: 100ms;
  }
  
  .delay-2 {
    animation-delay: 200ms;
  }

  /*Sinatura*/

  .signatureContainer {
    height: 200px;
    width: 30rem;
    background-color: #fff;
    border: 1px dashed #666;
    margin: 0 auto;
  }
  .signatureCanvas {
    width: 100%;
    height: 100%;
  }

  /*FORM*/
  form fieldset {
    border: none;
  }
  
  form fieldset + fieldset {
    margin-top: 3.5rem;
  }
  
  form fieldset legend {
    color: var(--color-card-title);
  
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.625rem;
  }
  
  fieldset .separator {
    margin-bottom: 2rem;
  }
  
  form .input-group {
    display: flex;
    margin-top: 1.5rem;
  }
  
  form .input-group .input-wrapper + .input-wrapper {
    margin-left: 1.5rem;
  }
  
  label {
    display: inline-block;
  
    font-family: IBM Plex Sans;
    font-weight: 500;
    color: #787880;
  
    margin-bottom: 1rem;
  }
  
  input {
    font-family: IBM Plex Sans;
    font-weight: 500;
  
    background: #FCFDFF;
  
    border: 1px solid #E1E3E6;
    border-radius: .313rem;
  
    padding: 0.75rem 1.5rem;
  
    width: 100%;
  
    color: var(--color-card-title);
  }
  
  ::placeholder {
    color: #BFBFCC;
  }
  
  .input-wrapper{
    width: 50%;
  }

  /*Button*/
  .button {
    padding: 0.75rem 3rem;
    border-radius: 0.313rem;
    border: 0;
  
    font-family: IBM Plex Sans;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.625rem;
    text-transform: uppercase;
  
    transition: all .2s;
  
    cursor:pointer;
  
    display: inline-flex;
    justify-content:center;
    align-items: center;
  }
  
  button.button {
    line-height: 1.5rem;
  }
  
  .button.orange {
    background: var(--color-secondary);
    color: var(--color-text);
  }
  
  .button.orange:hover {
    background: #FA9C2D
  }
  
  .button.green {
    background: var(--color-save);
    color: var(--color-text);
  }
  
  .button.green:hover {
    background: #3CC73C;
  }
  
  .button.gray {
    color: var(--color-card-content);
    background: var(--color-cancel);
  }
  
  .button.gray:hover {
    background: #ECEEF0;
  }

  .button.gray:focus {
    background: var(--focus);
    color: #FFFFFF;
  }
  
  .button.gray:active {
    background: var(--focus);
    color: #FFFFFF;
  }

  .button.red {
    background: var(--color-delete);
    color: var(--color-text);
  }
  
  .button.red:hover {
    background: #FA3F38;
  }
  
  .button.white {
    background: #FCFDFF;
    border: 1px solid #E1E3E6;
  }
  
  .button.white:hover {
    background: #F0F2F5;
  }
  
  .button-group {
    display: flex;
    align-items: center;
  }
  
  .button + .button {
    margin-left: 1rem;
  }
  
  .actions .button {
    width: 2.5rem;
    height: 2.5rem;
  
    padding: 0;
  }
  
  .actions .button + button {
    margin-left: 0.5rem;
  }
  


  /*Termo Aceite*/
  .termo_aceito {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.termo_aceito .input-block {
    background: none;
    padding: 0;
    border: 0;
    position: inherit;
}

.termo_aceito .input-block input[type="checkbox"] {
    width: inherit;
    display: none;
}

.termo_aceito .input-block input[type="checkbox"] + label {
    position: inherit;
    cursor: pointer;
} 

.termo_aceito .input-block input[type="checkbox"] + label:before {
    border: 1px solid var(--color-header-inner-title);
    content: "\00a0";
    display: inline-block;
    height: 24px;
    margin: 0 1.6rem 0 0;
    padding: 0;
    vertical-align: top;
    width: 24px;
    border-radius: 0.8rem;
    background-color: var(--color-box-footer);
}

.termo_aceito .input-block input[type="checkbox"]:checked + label:before {
    background: var(--color-save);
    color: var(--color-box-footer);
    content: url(../assets/checkbox-check.svg);
    text-align: center;
    line-height: 24px;
}

.termo_aceito a {
    text-decoration: none;
    color: var(--color-text-complement);
}


[class*=classic]:before {
  content:"Loading PDF...";
}


.classic-1 {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 30px;
  animation:c1 1s linear infinite alternate;
  color:#000;
}
@keyframes c1 {to{opacity: 0}}

.qrCode {
  display: flex;
  height: 13vh;
  transform: translateY(0px);
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.rpv-core__page-layer{
  z-index: 10;
  background-color:"transparent"
}


/*welcome*/
#page-home {
  height: 100vh;

  background: url('../assets/fundo.png') no-repeat 700px bottom ;
  color: #6C6C80;

  
}

#page-home .content {
  width: 100%;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#page-home .content header {
  margin: 48px 0 0;
}

#page-home .content main {
  flex: 1;
  max-width: 560px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

#page-home .content main h1 {
  font-size: 54px;
  color: var(--title-color);
}

#page-home .content main p {
  font-size: 24px;
  margin-top: 24px;
  line-height: 38px;
}

#page-home .content main a {
  width: 100%;
  max-width: 360px;
  height: 72px;
  background: var(--primary-color);
  border-radius: 8px;
  text-decoration: none;

  display: flex;
  align-items: center;
  overflow: hidden;

  margin-top: 40px;
}

#page-home .content main a span {
  display: block;
  background: rgba(0, 0, 0, 0.08);
  width: 72px;
  height: 72px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

#page-home .content main a span svg {
  color: #FFF;
  width: 20px;
  height: 20px;
}

#page-home .content main a strong {
  flex: 1;
  text-align: center;
  color: #FFF;
}

#page-home .content main a:hover {
  background: #2FB86E;
}

@media(max-width: 900px) {

  
  #page-home .content {
    align-items: center;
    text-align: center;
  }

  #page-home .content header {
    margin: 48px auto 0;
  }

  #page-home .content main {
    align-items: center;
  }

  #page-home .content main h1 {
    font-size: 42px;
  }

  #page-home .content main p {
    font-size: 24px;
  }
}

@media only screen
and (min-width: 768px)
{
  #page-home{
    background: url('../assets/fundo.png') no-repeat right center ;
    background-size: 47em !important;
  }

  
  #page-home .content main{
    max-width: 330px;
  }
  #page-home .content main h1{
    font-size: 28px;
  }
  #page-home .content main p {
    font-size: 20px;
  }
}

.rpv-default-layout__sidebar-headers{
  display:none !important
}


/*botao flutuante*/

#overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(220, 220, 220, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;
  will-change: opacity;
}
#overlay.show {
  visibility: visible;
  opacity: 1;
}

#hamburger {
  z-index: 10;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 10%;
  right: 5%;
  background-color: #ff5722;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 2px 2px 10px rgba(10, 10, 10, 0.3);
  transition: all 0.2s ease-in-out;
}
#hamburger .icon-bar {
  display: block;
  background-color: #ffffff;
  width: 22px;
  height: 2px;
  transition: all 0.3s ease-in-out;
}
#hamburger .icon-bar + .icon-bar {
  margin-top: 4px;
}

.navflutuante {
  z-index: 9;
  position: fixed;
  bottom: 2vh;
  right: 4.5vh;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.48);
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.material-icons {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.54);
}

#settings.show {
  transform: translateY(-125%);
}

#thumb.show {
  transform: translateY(-250%);
}

#create.show {
  transform: translateY(-375%);
}

#share.show {
  transform: translateY(-500%);
}

#hamburger.show {
  box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.48);
}
#hamburger.show #wrapper {
  transition: transform 0.4s ease-in-out;
  transform: rotateZ(90deg);
}
#hamburger.show #one {
  transform: translateY(6px) rotateZ(45deg) scaleX(0.9);
}
#hamburger.show #thr {
  transform: translateY(-6px) rotateZ(-45deg) scaleX(0.9);
}
#hamburger.show #two {
  opacity: 0;
}

.navflutuante.show {
  visibility: visible;
  opacity: 1;
}

@media(max-width: 590px) {
  .page-header {
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
  
  #top{
    height: 6vh;
  }

  #summary{
    margin-top: 1rem !important;
  }

  #summary .info {
    margin-bottom: 1rem !important;
  }
}